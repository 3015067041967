import request from "@/utils/request";

// 查询
export function getCompanyList(data) {
  return request({
    url: "/system/property/getCompanyList",
    method: "post",
    data
  });
}

// 新增
export function insertProperty(data) {
  return request({
    url: "/system/property/insertProperty",
    method: "post",
    data
  });
}

// 专利信息修改
export function updateProperty(data) {
  return request({
    url: "/system/property/updateProperty",
    method: "post",
    data
  });
}

// 单个查询
export function getProperty(data) {
  return request({
    url: "/system/property/getProperty",
    method: "get",
    params: data
  });
}

// 单个查询
export function getPatentInformation(data) {
  return request({
    url: "/system/companyPatent/getPatentInformation",
    method: "get",
    params: data
  });
}

// 单个查询
export function getSoftwareCopyrightInformation(data) {
  return request({
    url: "/system/companySoftwareCopyRight/getSoftwareCopyrightInformation",
    method: "get",
    params: data
  });
}

// 批量删除
export function deleteById(data) {
  return request({
    url: "/system/property/deleteById",
    method: "post",
    data
  });
}

// 单个查询法律状态
export function getLegalStatus(data) {
  return request({
    url: "/system/legalStatus/getLegalStatusList",
    method: "get",
    params: data
  });
}

// 单个查询新增
export function insertStatus(data) {
  return request({
    url: "/system/legalStatus/insertProperty",
    method: "post",
    data
  });
}

// 单个查询修改
export function updateLegalStatus(data) {
  return request({
    url: "/system/legalStatus/updateLegalStatus",
    method: "post",
    data
  });
}

// 单个查询删除
export function deleteStatusById(data) {
  return request({
    url: "/system/legalStatus/deleteById",
    method: "get",
    params: data
  });
}

// 软件著作权一览
export function getSoftwareCopyright(data) {
  return request({
    url: "/system/softwareCopyright/getCompanyList",
    method: "post",
    data
  });
}

// 软件著作权删除
export function deleteCopyrightById(data) {
  return request({
    url: "/system/softwareCopyright/deleteById",
    method: "post",
    data
  });
}

// 软件著作权新增
export function insertSoftwareCopyright(data) {
  return request({
    url: "/system/softwareCopyright/insertSoftwareCopyright",
    method: "post",
    data
  });
}

// 软件著作权修改
export function updateCopyrightProperty(data) {
  return request({
    url: "/system/softwareCopyright/updateProperty",
    method: "post",
    data
  });
}

// 软件著作权单个查询
export function selectById(data) {
  return request({
    url: "/system/softwareCopyright/selectById",
    method: "get",
    params: data
  });
}

//产品首页下载附件
export function findByPropertyFileKey(data) {
  return request({
    url: "/system/property/findByPropertyFileKey",
    method: "get",
    params: data
  });
}
