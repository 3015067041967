<template>
  <div>
    <div>
      <div class="newsLook">
        <el-form
          label-width="140px"
          :model="newsData"
          :rules="newsDataRules"
          ref="fieldRef"
        >
          <el-form-item label="软件全称：" prop="softwareFullName">
            <el-input v-model="newsData.softwareFullName"></el-input>
          </el-form-item>
          <el-form-item label="软件简称：">
            <el-input v-model="newsData.softwareName"></el-input>
          </el-form-item>
          <el-form-item label="登记号：" prop="fieldName">
            <el-input v-model="newsData.registerNumber"></el-input>
          </el-form-item>
          <el-form-item label="分类号：">
            <el-input v-model="newsData.classificationId"></el-input>
          </el-form-item>
          <el-form-item label="版本号：" prop="fieldName">
            <el-input v-model="newsData.versionId"></el-input>
          </el-form-item>
          <el-form-item label="著作人（国际）：" prop="author">
            <!-- <el-input v-model="newsData.author"></el-input> -->
            <el-select
              v-model="newsData.author"
              filterable
              allow-create
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="loading"
              style="width: 470px"
              :clearable="true"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.companyFullName"
                :value="item.companyCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="首次发表日：" prop="fieldName">
            <el-date-picker
              v-model="newsData.fristPublishDate"
              type="date"
              placeholder="请选择"
              value-format="yyyy-MM-dd"
              style="width: 200px !important"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="登记批准日：">
            <el-date-picker
              v-model="newsData.registrationApprovalDate"
              type="date"
              placeholder="请选择"
              value-format="yyyy-MM-dd"
              style="width: 200px !important"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="company_right" v-if="personType">
          <div>创建人：{{ newsData.createName }}</div>
          <div>修改人：{{ newsData.updateName }}</div>
          <div>创建时间：{{ newsData.createTime }}</div>
          <div>修改时间：{{ newsData.updateTime }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="btnClick">确定</el-button>
      <el-button @click="cancelItem">返回</el-button>
    </div>
  </div>
</template>

<script>
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import {
  insertSoftwareCopyright,
  updateCopyrightProperty,
  selectById,
} from "@/api/equity";
import { findCompany } from "@/api/caseData";
export default {
  name: "newsInfo",
  components: {
    DicRadioGroup,
  },
  computed: {
    newsDatas() {
      return Number(this.$route.query.id);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  created() {
    // this.searchField();
    if (this.personType) {
      this.search();
    }
  },
  data() {
    return {
      newsData: {
        softwareFullName: "", //软件全称
        softwareName: "", //软件简称
        classificationId: "", //分类号
        registerNumber: "", //登记号
        versionId: "", //版本号
        author: "", //著作人
        fristPublishDate: "", //首次发表日
        registrationApprovalDate: "", //批准登记日
      },
      newsDataRules: {
        softwareFullName: [
          { required: true, message: "请输入软件全称", trigger: "blur" },
        ],
        author: [{ required: true, message: "请输入著作人", trigger: "blur" }],
      },
      list: "",
      options: [],
      loading: false,
    };
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const companyName = query;
          const res = await findCompany({ companyName: companyName });
          this.options = res.data;
        }, 200);
      } else {
        this.options = [];
      }
    },
    cancelItem() {
      this.$router.go(-1);
    },
    async btnClick() {
      //修改
      Object.assign(this.newsData, {
        Authorization: this.Authorization,
      });
      this.$refs.fieldRef.validate(async (valid) => {
        if (valid) {
          if (this.personType) {
            const res = await updateCopyrightProperty(this.newsData);
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            }
          } else {
            const res = await insertSoftwareCopyright(this.newsData);
            if (res.code == 200) {
              this.$message.success("添加成功");
              this.$router.go(-1);
            }
          }
        }
      });
    },
    async search() {
      const res = await selectById({ id: this.newsDatas });
      if (res.code == 200) {
        this.newsData = res.data;
        // this.list = this.newsData.knowTecheslist.map(item => {
        //   return (item = item.fieldName);
        // });
        // this.list = this.list.join(",");
        // if (!this.newsData.knowField) {
        //   this.newsData.knowField = [];
        // }
        this.options = [
          {
            companyFullName: this.newsData.companyFullName,
            companyCode: this.newsData.author,
          },
        ];
      }
    },
  },
  mounted() {
    document.getElementsByClassName("newsLook")[0].style.height =
      document.body.clientHeight - 250 + "px";
  },
};
</script>

<style lang="less" scoped>
.newsLook {
  background-color: white;
  padding: 30px 10px 10px 10px;
  overflow: auto;
  // height: 720px;
  .el-form {
    .el-form-item {
      margin-bottom: 20px;
      .el-textarea {
        width: 80%;
      }
      .el-input {
        width: 470px;
      }
      /deep/ .el-textarea__inner {
        height: 100%;
      }
    }
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: white;
  color: #606266;
  cursor: default;
}
.company_right {
  margin: 0px 20px;
  font-size: 13px;
  color: #7c7f8e;
}
</style>
